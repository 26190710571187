$(document).ready(function() {
  var $slick = $('.js-slider');

  function initSlider() {
    if (!$slick.length) {
      return;
    }

    if ($slick.hasClass('slick-initialized')) {
      $slick.slick('unslick');
    }

    $slick.each(function(index, slider) {
      var itemShow = $(slider).data('item-show');
      var breakpointItemShow = $(slider).data('breakpoint-item-show');
      var showDot = $(slider).data('show-dot');
      var breakpointShowDot = $(slider).data('breakpoint-show-dot');
      var showArrow = $(slider).data('show-arrow');
      var breakpointShowArrow = $(slider).data('breakpoint-show-arrow');
      var breakpoint = $(slider).data('breakpoint');
      var centerMode = $(slider).data('center-mode');
      var dataSwipe = $(slider).data('swipe');
      var swipeBreakpoint = $(slider).data('breakpoint-swipe');

      $(slider).slick({
        slidesToShow: itemShow || 1,
        slidesToScroll: 1,
        dots: showDot,
        arrows: showArrow,
        swipe: dataSwipe,
        responsive: [{
          breakpoint: breakpoint,
          settings: {
            swipe: swipeBreakpoint,
            slidesToShow: breakpointItemShow,
            centerMode: centerMode,
            arrows: breakpointShowArrow,
            dots: breakpointShowDot
          }
        }]
      });
    });
  };

  initSlider();
});
